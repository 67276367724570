import React from 'react';
import { classNames } from '../../utils/utils';

const calculatePages = (totalPages, currentPage) => {
  return [
    currentPage - 1 > 0 && '<',
    currentPage - 1 > 0 && currentPage - 1,
    currentPage,
    currentPage + 1 <= totalPages && currentPage + 1,

    // Show '...' when there are at least 3 items more at the right X 1 ... 2
    currentPage + 3 <= totalPages && '...',
    currentPage + 2 <= totalPages && totalPages,

    currentPage + 1 <= totalPages && '>'
  ].filter(page => !!page);
};

const goTo = (currentPage, location) => {
  if (location === '>') {
    return currentPage + 1;
  }
  if (location === '...') {
    return currentPage + 2;
  }
  if (location === '<') {
    return currentPage - 1;
  }
  return location;
};

const Pagination = ({ total, perPage, currentPage, setPage }) => {
  const totalPages = Math.ceil(total / perPage);
  const pages = calculatePages(totalPages, currentPage);

  if (!total || total === 0) {
    return null;
  }

  return (
    <div className="flex font-light">
      {pages.map(page => (
        <div
          key={page}
          className={classNames(
            'm-2',
            'cursor-pointer',
            'select-none',
            page === currentPage ? 'font-bold' : 'text-grey'
          )}
          onClick={() => setPage(goTo(currentPage, page))}
        >
          {page}
        </div>
      ))}
    </div>
  );
};

Pagination.defaultProps = {
  setPage: () => {}
};

export default Pagination;
