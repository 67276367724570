import React from 'react';

const Spinner = () => (
  <div className="flex">
    <span class="flex h-3 w-3 relative ">
      <span class="animate-pulse absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span>
    </span>
    <span class="flex h-3 w-3 relative ml-2">
      <span class="animate-pulse absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span>
    </span>
    <span class="flex h-3 w-3 relative ml-2">
      <span class="animate-pulse absolute inline-flex h-full w-full rounded-full bg-gray-400 opacity-75"></span>
    </span>
  </div>
);

export default Spinner;
