import React from 'react';

const Search = ({ search = () => {} }) => {
  return (
    <div className="Search w-full h-10 relative outline-none z-10" tabIndex="0">
      <div className="pointer-events-none absolute top left pl-3 h-full flex items-center">
        <svg
          className="fill-current pointer-events-none text-gray-700 w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
        </svg>
      </div>
      <input
        type="text"
        className="w-full h-full py-2 pr-4 pl-10 outline-none appearance-none bg-gray-100 border border-transparent rounded placeholder-gray-700 text-gray-900 font-light focus:bg-white focus:border-gray-700"
        placeholder="Zoeken..."
        aria-label="Zoek"
        onChange={e => search(e.target.value)}
      />
    </div>
  );
};

export default Search;
