import 'react-app-polyfill/ie9';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import './theme/tailwind.css';

// Prevent right mouse click
if (process.env.NODE_ENV === 'production') {
  document.addEventListener('contextmenu', event => event.preventDefault());
}

ReactDOM.render(<App />, document.getElementById('root'));
