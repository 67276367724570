import React, { useState } from 'react';

import api, { setToken } from '../../utils/api';
import { classNames } from '../../utils/utils';

const login = (onLogin, setBusy, setError, credentials) => {
  setBusy(true);

  api
    .post('/auth/login', { body: credentials })
    .then(({ access_token }) => {
      setToken(access_token);
      onLogin();
    })
    .catch(err => {
      if (err.status === 401) {
        setError('Uw gebruikersnaam of wachtwoord is incorrect.');
      } else if (err.status === 429) {
        setError('Te veel inlogpogingen. Probeer het later opnieuw');
      } else {
        setError('Er is iets fout gelopen. Probeer het later opnieuw');
      }

      setBusy(false);
    });
};

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  const [busy, setBusy] = useState(false);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full py-12 px-6">
        <div className="text-3xl font-bold text-center">{process.env.REACT_APP_HEADER_TITLE}</div>
        <p className="mt-5 leading-5 text-center text-gray-600">
          Vul je logingegevens in om toegang te krijgen tot het fotoarchief.
        </p>
        <form className="mt-6">
          <div className="rounded-md shadow-sm">
            <input
              className={classNames(
                'relative focus:z-20 text-sm placeholder-gray-500 appearance-none rounded-none w-full px-3 py-2 border text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-gray-600 leading-5',
                error ? 'border-red-600' : 'border-gray-300'
              )}
              placeholder="Gebruikersnaam"
              type="text"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <input
              className={classNames(
                'relative focus:z-20 text-sm placeholder-gray-500 appearance-none rounded-none w-full px-3 py-2 border text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-gray-600 leading-5 -mt-px',
                error ? 'border-red-600' : 'border-gray-300'
              )}
              placeholder="Wachtwoord"
              name="password"
              value={password}
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          {error && <p className="mt-1 mb-4 text-red-600 text-sm">{error}</p>}

          <div className="mt-5">
            <button
              type="button"
              disabled={busy}
              className="block w-full py-2 px-3 rounded-md bg-gray-900 text-white"
              onClick={() => login(onLogin, setBusy, setError, { username, password })}
            >
              Inloggen
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

Login.defaultProps = {
  onLogin: () => {},
};

export default Login;
