import React from 'react';
import LazyLoadImage from './LazyLoadImage';

/**
 * Datestring has following format: Y-m-d H:i:s
 * E.g. 2019-02-21 11:19:00
 */
const formatDate = dateString => {
  const [date, time] = dateString.split(' ');
  const [year, month, day] = date.split('-');
  const [hours, minutes] = time.split(':');

  return `${day}/${month}/${year} om ${hours}:${minutes}`;
};

const Image = ({ placeholder, src, name, source, onClick }) => (
  <div className="h-full w-full relative group cursor-pointer" onClick={onClick}>
    <LazyLoadImage
      placeholder={placeholder}
      src={src}
      alt={name}
      className="h-full w-full object-contain border border-gray-500 pointer-events-none"
    />
    <div className="absolute top-0 left-0 h-full w-full pointer-events-none opacity-50 group-hover:bg-black" />
    <div className="absolute top-0 left-0 h-full w-full items-end hidden p-4 group-hover:flex">
      <div>
        <div className="text-white text-lg">{source.description}</div>
        <div className="text-white mt-1">{formatDate(source.taken_at)}</div>
      </div>
    </div>
  </div>
);

Image.defaultProps = {
  onClick: () => {}
};

export default Image;
