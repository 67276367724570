import React, { Component } from 'react';
import { Chart as FrappeChart } from 'frappe-charts/dist/frappe-charts.min.esm';

import 'frappe-charts/dist/frappe-charts.min.css';

class Chart extends Component {
  componentDidMount() {
    const { onSelect, ...rest } = this.props;

    this.graph = new FrappeChart(this.chart, {
      is_navigable: !!onSelect,
      ...rest
    });

    if (onSelect) {
      this.graph.parent.addEventListener('data-select', onSelect);
    }
  }

  componentWillReceiveProps(props) {
    this.graph.update(props.data);
  }

  render() {
    return <div ref={chart => (this.chart = chart)} />;
  }
}

export default Chart;
